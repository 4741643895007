<template>
  <v-main>
    <router-view />
  </v-main>
</template>

<script>
export default {
  name: "BlankLayout",
};
</script>

<style lang="scss" scoped></style>
